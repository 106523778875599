import React, { useEffect, useRef, useState } from 'react';
import { ListItemButton, ListItemIcon, ListItemText, List, Collapse, Divider, ListItem, IconButton, ListItemAvatar, Avatar, Button, Box } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { useMenuData } from '../../Context/MenuDataContext';
import { useAuth } from '../../Context/AuthContext';
import 'material-icons/iconfont/material-icons.css';
import { Icon } from '@mui/material';
import { FormControl } from '@mui/material';
import iconsList from '../../constants/iconsList';
import { Virtuoso } from 'react-virtuoso'
import { Menu } from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import CustomActionButton from '../CustomActionButton';
import ValidationTextFields from '../ValidationTextField';
import CustomEditingBox from '../CustomEditingBox';
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';


const MenuItemSetup = ({ cancel, updateMenuItem, createMenuItem, deleteMenuItem, menuId, subMenuId, icon, text, isMainItem }) => {

  const [iconSelect, setIconSelect] = useState(icon);
  const [menuTitle, setMenuTitle] = useState(text);

  const [iconMenuHeight, setIconMenuHeight] = useState(null);
  const textRef = useRef(null);
  const listRef = useRef(null);


  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  useEffect(() => {
    console.log(menuId, subMenuId, iconSelect, menuTitle)
  }, [])

  const onUpdateMenuItem = () => {
    updateMenuItem(menuId, subMenuId, iconSelect, menuTitle);
  };

  const onCreateSubMenuItem = () => {
    createMenuItem(menuId, null, 'home', "New Sub Item")
  };

  const onDeleteMenuItem = () => {
    deleteMenuItem(menuId, subMenuId)
  };

  useEffect(() => {
    console.log(textRef.current.clientHeight)
    setIconMenuHeight(textRef.current.clientHeight);

  }, [])


  const handleItemClick = (icon) => {
    setIconSelect(icon);
    setAnchorEl(null);
  };


  const handleTextChange = (value) => {
    setMenuTitle(value);
  };

  const onCancel = (e) => {
    cancel(e.target);
  };


  // const handleCreateSubDirectoryItem = (e) => {
  //   createSubDirectoryItem(menuId);
  // };


  const StyledMenu = styled((props) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      {...props}
    />
  ))(({ theme }) => ({
    '& .MuiPaper-root': {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      minWidth: 180,
      color:
        theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
      boxShadow:
        'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
      '& .MuiMenu-list': {
        padding: '4px 0',
      },
      '& .MuiMenuItem-root': {
        '& .MuiSvgIcon-root': {
          fontSize: 18,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
        '&:active': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity,
          ),
        },
      },
    },
  }));


  return (
    <>
      {!isMainItem && <SubdirectoryArrowRightIcon />}
      <CustomEditingBox editing cancel={onCancel} updateMenuItem={onUpdateMenuItem} deleteMenuItem={onDeleteMenuItem}>

        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <FormControl sx={{ minWidth: 80 }}>

            <Button
              id="demo-customized-button"
              aria-haspopup="true"
              aria-controls={open ? 'demo-customized-menu' : undefined}
              aria-expanded={open ? 'true' : undefined}
              variant="outlined"
              disableElevation
              sx={iconMenuHeight && { height: iconMenuHeight }}
              onClick={handleClick}
              endIcon={<KeyboardArrowDownIcon />}
            >
              <Icon>{iconSelect}</Icon>
            </Button>

            <StyledMenu
              id="demo-customized-menu"
              MenuListProps={{
                'aria-labelledby': 'demo-customized-button',
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              style={{ height: "350px", width: '200px', margin: '0px' }}

            >

              <Virtuoso
                ref={listRef}
                anchorEl={anchorEl}
                // style={{ height: '100%' }}
                style={{ height: "350px", width: '200px', margin: '0px' }}
                totalCount={iconsList.length}
                data={iconsList}
                itemContent={(index, icon) =>
                  <div
                    style={{
                      padding: '0px',
                    }}
                  >
                    <ListItem key={icon} onClick={() => handleItemClick(icon)}>
                      <ListItemIcon>
                        <Icon>{icon}</Icon>
                      </ListItemIcon>
                      <ListItemText
                        primary={icon.split('_')
                          .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                          .join(' ')}
                      />

                    </ListItem>
                  </div>
                }
              />
            </StyledMenu>
          </FormControl >

          <ValidationTextFields initialValue={menuTitle} inputRef={textRef} fullWidth id="menuTitle" label="Title" onChange={handleTextChange} limit={25} />

        </Box>

        {isMainItem &&
          <Box sx={{ display: 'flex' }}>
            <CustomActionButton icon={"subdirectory_arrow_right"}
              onClick={onCreateSubMenuItem}
            />
          </Box>
        }

      </CustomEditingBox>
    </>
  );
};





export default MenuItemSetup;
