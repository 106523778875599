// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getStorage, connectStorageEmulator, ref, getDownloadURL, listAll } from "firebase/storage";
import {
    getAuth,
    signOut,
    connectAuthEmulator,
    signInWithEmailAndPassword,
    setPersistence,
    onAuthStateChanged,
    browserSessionPersistence,
    getMultiFactorResolver,
    PhoneAuthProvider,
    PhoneMultiFactorGenerator,
    RecaptchaVerifier,
} from "firebase/auth";

import {
    getFirestore,
    connectFirestoreEmulator,
    collection,
    doc,
    where,
    orderBy,
    limit,
    query,
    addDoc,
    getDoc,
    getDocs,
    setDoc,
    onSnapshot,
    deleteDoc,
    updateDoc,
    startAfter,
    runTransaction
} from "firebase/firestore";


const emulator = process.env.REACT_APP_EMULATOR_STATUS;


// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyA9kAplValqGPkVbhPuWD3UVpSGsUQwdu0",
    authDomain: "clintonmurtagh-4c00d.firebaseapp.com",
    projectId: "clintonmurtagh-4c00d",
    storageBucket: "clintonmurtagh-4c00d.appspot.com",
    messagingSenderId: "656208371687",
    appId: "1:656208371687:web:e7f61282e3886040456742",
    measurementId: "G-44PG7ZM80R"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const db = getFirestore(app);
export const storage = getStorage(app);
export const auth = getAuth(app);

export const dbFunctions = {
    collection,
    doc,
    where,
    orderBy,
    limit,
    query,
    addDoc,
    getDoc,
    getDocs,
    setDoc,
    deleteDoc,
    updateDoc,
    onSnapshot,
    startAfter,
    runTransaction
};

export const storageFunctions = {
    ref,
    getDownloadURL,
    listAll
};

export const authFunctions = {
    browserSessionPersistence,
    signOut,
    setPersistence,
    onAuthStateChanged,
    signInWithEmailAndPassword,
    getMultiFactorResolver,
    PhoneAuthProvider,
    PhoneMultiFactorGenerator,
    RecaptchaVerifier,
};

if (emulator === "true") {
    connectFirestoreEmulator(db, 'c.cm', 8080);
    connectStorageEmulator(storage, "c.cm", 9199);
    connectAuthEmulator(auth, "http://c.cm:9099");
}

// Get one image at path
export async function fetchImage(imageName) {
    try {
        // Create a reference to the file we want to download
        const storage = getStorage();
        const starsRef = ref(storage, imageName);
        // Get the download URL
        const url = await getDownloadURL(starsRef);

        return { success: true, message: `Success`, url: url };
    } catch (error) {
        // A full list of error codes is available at
        // https://firebase.google.com/docs/storage/web/handle-errors
        return handleErrorCodes(error);
    }
}

// Get all images in directory
export async function fetchImages(path) {
    console.log(path)
    try {
        const storageRef = ref(storage, path); // Replace with your actual storage path
        const imagesList = await listAll(storageRef);

        const urls = await Promise.all(
            imagesList.items.map(async (item) => {
                const url = await getDownloadURL(item);
                return url;
            })
        );

        return { success: true, message: `Success`, urls: urls };
    } catch (error) {
        return handleErrorCodes(error);
    }
};


function handleErrorCodes(error) {
    switch (error.code) {
        case 'storage/object-not-found':
            // File doesn't exist
            console.error('File not found:', error.message);
            return { success: false, message: `File not found:, ${error.message}` }
        case 'storage/unauthorized':
            // User doesn't have permission to access the object
            console.error('Unauthorized access:', error.message);
            return { success: false, message: `Unauthorized access:, ${error.message}` }
        case 'storage/canceled':
            // User canceled the upload
            console.error('Upload canceled:', error.message);
            return { success: false, message: `Upload canceled:, ${error.message}` }
        case 'storage/unknown':
            // Unknown error occurred, inspect the server response
            console.error('Unknown error:', error.message);
            return { success: false, message: `Unknown error:, ${error.message}` }
        default:
            console.error('Error:', error.message);
            return { success: false, message: `Error:, ${error.message}` }
    }
}





