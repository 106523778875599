import React, { useEffect, useRef, useState } from 'react';
import { ListItemButton, ListItemIcon, ListItemText, List, Collapse, Divider, ListItem, IconButton, ListItemAvatar, Avatar, Button, Box } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { useMenuData } from '../../Context/MenuDataContext';
import { useAuth } from '../../Context/AuthContext';
import 'material-icons/iconfont/material-icons.css';
import { Icon } from '@mui/material';
import { FormControl } from '@mui/material';
import iconsList from '../../constants/iconsList';
import { Virtuoso } from 'react-virtuoso'
import { Menu } from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import CustomActionButton from '../CustomActionButton';
import ValidationTextFields from '../ValidationTextField';
import CustomEditingBox from '../CustomEditingBox';
import MenuItem from './MenuItem';
import MenuItemSetup from './MenuItemSetup';



const menuItems = [
  {
    id: 'about',
    text: 'About',
  }
]

const logoutMenuItem =
{
  id: 'logout',
  noEdit: true,
  dividerAbove: true,
  icon: <ExitToAppIcon />,
  text: 'Logout',
}




const CustomMenu = ({ currentPage, handleItemClick, drawerOpen }) => {


  const { userAdmin } = useAuth();

  const {
    menuData,
    deleteMenuItem,
    createMenuItem,
    updateMenuItem } = useMenuData();

  const [editingItem, setEditingItem] = useState(false);



  const onUpdateMenuItem = async (id, subItemId, text, icon) => {
    await updateMenuItem(id, subItemId, text, icon);
    setEditingItem(false)
  }

  const onCreateMenuItem = async (id, subItemId, icon, text) => {
    await createMenuItem(id, subItemId, icon, text);
  }


  const onDeleteMenuItem = async (id, subItemId) => {
    await deleteMenuItem(id, subItemId);
    setEditingItem(false)
  }

  const addMenuItem = () => {
    onCreateMenuItem(null, null, 'home', 'New Item')
    // setEditingItem(true)
  }






  return (
    <div>
      {menuData.map((item, index) => (
        <>
          <MenuItem
            currentPage={currentPage}
            key={index}
            item={item}
            handleItemClick={handleItemClick}
            drawerOpen={drawerOpen}
            createMenuItem={onCreateMenuItem}
            updateMenuItem={onUpdateMenuItem}
            deleteMenuItem={onDeleteMenuItem}
          // createSubDirectoryItem={createSubDirectoryItem}
          />
        </>
      ))}

      {userAdmin &&
        <>
          {!editingItem ?
            <CustomActionButton icon={"add"} onClick={addMenuItem} />
            :
            <MenuItemSetup
              updateMenuItem={onUpdateMenuItem}
              createMenuItem={onCreateMenuItem}
              deleteMenuItem={onDeleteMenuItem} isMainItem />
          }
        </>
      }


      <MenuItem currentPage={currentPage} key={'logout'} item={logoutMenuItem} handleItemClick={handleItemClick} drawerOpen={drawerOpen} />
    </div>
  );
};

export default CustomMenu;
