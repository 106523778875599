// ThemeContext.js
import React, { createContext, useContext, useMemo, useState, useEffect } from 'react';
import { createTheme, ThemeProvider as MuiThemeProvider, useTheme } from '@mui/material';

const defaultTheme = createTheme();

const ThemeContext = createContext();

export const useThemeContext = () => {
    const context = useContext(ThemeContext);
    if (!context) {
        throw new Error('useThemeContext must be used within a ThemeProvider');
    }
    return context;
};

export const ThemeProvider = ({ children }) => {
    // Retrieve theme preference from local storage or use default
    const storedThemePreference = localStorage.getItem('isDarkMode');
    const initialIsDarkMode = storedThemePreference ? JSON.parse(storedThemePreference) : false;

    const [isDarkMode, setIsDarkMode] = useState(initialIsDarkMode);

    // Update local storage when theme preference changes
    useEffect(() => {
        localStorage.setItem('isDarkMode', JSON.stringify(isDarkMode));
    }, [isDarkMode]);

    const theme = useTheme();

    const roundedCorners = 15;
    const customTheme = useMemo(
        () =>
            createTheme({
                components: {
                    MuiImageListItem: {
                        styleOverrides: {
                            root: {
                                transition: 'transform 0.3s ease-in-out', // Adjust the duration and easing as needed
                                '&:hover': {
                                    transform: 'scale(1.03)', // Adjust the scale factor as needed
                                    borderRadius: 0
                                },
                            },
                        },
                    },
                    MuiButton: {
                        styleOverrides: {
                            contained: {
                                borderRadius: roundedCorners, // Set the border radius for contained buttons
                            },
                            outlined: {
                                borderRadius: roundedCorners,
                                color: isDarkMode ? theme.palette.common.white : theme.palette.primary.main, // Set the text color for the outlined button
                                borderColor: isDarkMode ? theme.palette.common.white : theme.palette.primary.main, // Set the text color for the outlined button
                            },
                            text: {
                                borderRadius: roundedCorners,
                                color: isDarkMode ? theme.palette.common.white : theme.palette.primary.main, // Set the text color for the outlined button
                                borderColor: isDarkMode ? theme.palette.common.white : theme.palette.primary.main, // Set the text color for the outlined button
                            },
                        },
                    },
                    MuiOutlinedInput: {
                        styleOverrides: {
                            root: {
                                borderRadius: roundedCorners, // Set the border radius for text fields
                            },
                        },
                    },
                    // MuiSkeleton: {
                    //     styleOverrides: {
                    //         root: {
                    //             borderRadius: '5px', // Adjust the radius as needed
                    //         },
                    //     },
                    // },

                },
                palette: {
                    mode: isDarkMode ? 'dark' : 'light',
                    ...(isDarkMode
                        ? {
                            primary: {
                                main: '#015982',
                            },
                            secondary: {
                                main: '#4b8516',
                            },
                            // Additional palette values for dark mode
                            background: {
                                default: '#121212',
                                paper: '#303030',
                            },
                        }
                        : {
                            primary: {
                                main: '#015982',
                            },
                            secondary: {
                                main: '#4b8516',
                            },
                            // Additional palette values for light mode
                            background: {
                                default: '#ffffff',
                                paper: '#ebebeb',
                            },
                        }),
                },
            }),
        [isDarkMode]
    );


    return (
        <ThemeContext.Provider value={{ setIsDarkMode, isDarkMode }}>
            <MuiThemeProvider theme={customTheme}>{children}</MuiThemeProvider>
        </ThemeContext.Provider>
    );
};
