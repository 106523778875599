import React, { useEffect, useRef, useState } from 'react';
import { ListItemButton, ListItemIcon, ListItemText, List, Collapse, Divider, ListItem, IconButton, ListItemAvatar, Avatar, Button, Box } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { useMenuData } from '../../Context/MenuDataContext';
import { useAuth } from '../../Context/AuthContext';
import 'material-icons/iconfont/material-icons.css';
import { Icon } from '@mui/material';
import { FormControl } from '@mui/material';
import iconsList from '../../constants/iconsList';
import { Virtuoso } from 'react-virtuoso'
import { Menu } from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import CustomActionButton from '../CustomActionButton';
import ValidationTextFields from '../ValidationTextField';
import CustomEditingBox from '../CustomEditingBox';
import MenuItemSetup from './MenuItemSetup';
import MenuSubItems from './MenuSubItems';



// Define your styled ListItem
const StyledListItem = styled(ListItem)({
  '&:hover': {
    cursor: 'pointer',
  },
});

const MenuItem = ({ currentPage, item, handleItemClick, drawerOpen, createMenuItem, updateMenuItem, deleteMenuItem }) => {


  const [modifyItem, setModifyItem] = useState(false);


  const changeMenuItem = () => {
    setModifyItem(true)
  };

  const onUpdateMenuItem = (id, subItemId, text, icon, isMainItem) => {
    updateMenuItem(id, subItemId, text, icon, isMainItem);
    setModifyItem(false)
  };

  const onCreateMenuItem = async (id, subItemId, text, icon) => {
    await createMenuItem(id, subItemId, text, icon);
  }


  const onDeleteMenuItem = (id, subItemId) => {
    deleteMenuItem(id, subItemId);
    setModifyItem(false)
  };


  const onCancel = () => {
    setModifyItem(false)
  };



  const { userAdmin } = useAuth();

  const [open, setOpen] = React.useState(item?.subItems?.find(obj => obj.id === currentPage) ? true : false);

  const handleToggle = () => {
    setOpen(!open);
  };

  const hasMenuItems = (item.subItems && item.subItems.length > 0) ? true : false;

  return (
    <>
      {item.dividerAbove &&
        <Divider />}


      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {!modifyItem &&
          <>
            {hasMenuItems ?
              <StyledListItem
                onClick={hasMenuItems ? handleToggle : () => { handleItemClick(item) }}
                secondaryAction={
                  <IconButton edge="end" aria-label="delete" onClick={handleToggle}>
                    {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                  </IconButton>
                }
              >
                <ListItemIcon>
                  {item.icon &&
                    <Icon>{item.icon}</Icon>
                  }

                </ListItemIcon>
                {drawerOpen && <ListItemText primary={item.text} />}
              </StyledListItem>
              :

              <ListItemButton selected={currentPage === item.id} onClick={hasMenuItems ? handleToggle : () => { handleItemClick(item) }}>
                <ListItemIcon>

                  {item.icon &&
                    <Icon>{item.icon}</Icon>
                  }

                </ListItemIcon>
                {drawerOpen && <ListItemText primary={item.text} />}
              </ListItemButton>
            }
          </>
        }

        {userAdmin && !item.noEdit &&
          <>
            {!modifyItem ?
              <CustomActionButton icon={"edit"} onClick={changeMenuItem} />
              :
              <MenuItemSetup
                menuId={item.id}
                subMenuId={null}
                icon={item.icon}
                text={item.text}
                createMenuItem={onCreateMenuItem}
                updateMenuItem={onUpdateMenuItem}
                cancel={onCancel}
                deleteMenuItem={onDeleteMenuItem}
                isMainItem
              />
            }
          </>
        }


      </Box>

      <MenuSubItems item={item} open={open||modifyItem} handleItemClick={handleItemClick} drawerOpen={drawerOpen} updateMenuItem={onUpdateMenuItem} deleteMenuItem={deleteMenuItem} />

      {/* <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {hasMenuItems && (
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Divider />
            <List component="div" disablePadding>
              {item.subItems.map((subItem, index) => (
                <ListItemButton selected={currentPage === subItem.id} key={index} sx={{ pl: 4 }} onClick={() => { handleItemClick(subItem) }}>
                  <ListItemIcon>
                    {subItem.icon &&
                      <Icon>{subItem.icon}</Icon>
                    }
                  </ListItemIcon>
                  {drawerOpen && <ListItemText primary={subItem.text} />}
                </ListItemButton>

              ))}
            </List>
          </Collapse>
        )}

      </Box> */}
    </>
  );
};




export default MenuItem;
