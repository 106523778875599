export const hexToRgb = (hex, alpha) => {
  const hexColor = hex.replace(/^#/, '');
  const bigint = parseInt(hexColor, 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;
  return `rgba(${r},${g},${b},${alpha})`;
};


// Creates a unique id for new menu items added
export const createMenuItemId = () => {
  // Remove special characters and spaces

  // Generate a random 5-character string
  const randomString = generateRandomString(15);

  return randomString;
}

function generateRandomString(length) {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let randomString = '';
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    randomString += characters.charAt(randomIndex);
  }
  return randomString;
}