import React, { useEffect, useRef, useState } from 'react';
import { ListItemButton, ListItemIcon, ListItemText, List, Collapse, Divider, ListItem, IconButton, ListItemAvatar, Avatar, Button, Box } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { useMenuData } from '../../Context/MenuDataContext';
import { useAuth } from '../../Context/AuthContext';
import 'material-icons/iconfont/material-icons.css';
import { Icon } from '@mui/material';
import { FormControl } from '@mui/material';
import iconsList from '../../constants/iconsList';
import { Virtuoso } from 'react-virtuoso'
import { Menu } from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import CustomActionButton from '../CustomActionButton';
import ValidationTextFields from '../ValidationTextField';
import CustomEditingBox from '../CustomEditingBox';
import MenuItemSetup from './MenuItemSetup';



// Define your styled ListItem
const StyledListItem = styled(ListItem)({
  '&:hover': {
    cursor: 'pointer',
  },
});

const MenuSubItem = ({ currentPage, item, subItem, index, handleItemClick, drawerOpen, updateMenuItem, deleteMenuItem }) => {


  const [modifySubItem, setModifyISubItem] = useState(false);


  const changeMenuItem = () => {
    setModifyISubItem(true)
  };

  const onUpdateMenuItem = (menuId, subMenuId, text, icon) => {
    updateMenuItem(menuId, subMenuId, text, icon);
    setModifyISubItem(false)
  };


  const onDeleteMenuItem = (menuId, subMenuId) => {
    deleteMenuItem(menuId, subMenuId);
    setModifyISubItem(false)
  };


  const onCancel = () => {
    setModifyISubItem(false)
  };


  const { userAdmin } = useAuth();
  console.log("userAdmin:" + userAdmin)

  const hasMenuItems = (item.subItems && item.subItems.length > 0) ? true : false;

  return (

    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      {!modifySubItem &&
        <ListItemButton selected={currentPage === subItem.id} key={index} sx={{ pl: 4 }} onClick={() => { handleItemClick(subItem) }}>
          <ListItemIcon>
            {subItem.icon &&
              <Icon>{subItem.icon}</Icon>
            }
          </ListItemIcon>
          {drawerOpen && <ListItemText primary={subItem.text} />}
        </ListItemButton>
      }
      {userAdmin &&
        <>
          {!modifySubItem ?
            <CustomActionButton icon={"edit"} onClick={changeMenuItem} />
            :
            <MenuItemSetup
              menuId={item.id}
              subMenuId={subItem.id}
              icon={subItem.icon}
              text={subItem.text}
              updateMenuItem={onUpdateMenuItem}
              cancel={onCancel}
              deleteMenuItem={onDeleteMenuItem}
              />
          }
        </>
      }

    </Box>
  );
};




export default MenuSubItem;
