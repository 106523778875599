import React, { useEffect, useRef, useState } from 'react';
import { Typography } from '@mui/material';
import { useAuth } from '../../Context/AuthContext';
import 'material-icons/iconfont/material-icons.css';

import { useModuleListData } from '../../Context/ModuleDataContext';




const ModuleList = () => {




  const { userAdmin } = useAuth();

  const { pageId, moduleListData } = useModuleListData();

  const objectAsString = JSON.stringify(moduleListData, null, 2); // The third parameter (2) is for indentation



  return (
    <div>
      <Typography alignContent={'center'}>{pageId}</Typography>
      <Typography alignContent={'center'}> {objectAsString}</Typography>
    </div>
  );
};

export default ModuleList;
