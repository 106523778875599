import React, { useState, useRef, useEffect } from 'react';
import {
    getAuth,
    getMultiFactorResolver,
    PhoneAuthProvider,
    PhoneMultiFactorGenerator,
    RecaptchaVerifier,
    signInWithEmailAndPassword
} from 'firebase/auth';

import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

const SignIn = () => {
    const recaptchaVerifierRef = useRef();

    const auth = getAuth();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [smsCode, setSmsCode] = useState('');
    const [verificationId, setVerificationId] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');
    const [resolver, setResolver] = useState(null);

    var recaptchaVerifier;

    useEffect(() => {
        if (resolver) {
            try {
                recaptchaVerifier = new RecaptchaVerifier(
                    recaptchaVerifierRef.current,

                    // Optional reCAPTCHA parameters.
                    {
                        "size": "normal",
                        "callback": function (response) {
                            console.log("SOLVED")
                            // reCAPTCHA solved, you can proceed with
                            // phoneAuthProvider.verifyPhoneNumber(...).

                        },
                        "expired-callback": function () {
                            // Response expired. Ask user to solve reCAPTCHA again.
                            // ...
                        }
                    }, auth
                );
            }
            catch (error) {
                console.log("CAPTCHA FAILED: " + error)
            }
        }
    }, [resolver])




    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };
    const handleSmsChange = (e) => {
        setSmsCode(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            // User is not enrolled with a second factor and is successfully signed in.
            // Handle the success case as needed.
            setErrorMessage('');
        } catch (error) {
            if (error.code === 'auth/multi-factor-auth-required') {
                const resolver = getMultiFactorResolver(auth, error);

                // Ask user which second factor to use.
                if (resolver.hints.length > 0 && resolver.hints[0].factorId === PhoneMultiFactorGenerator.FACTOR_ID) {
                    // const phoneInfoOptions = {
                    //     multiFactorHint: resolver.hints[selectedIndex],
                    //     session: resolver.session
                    // };
                    setResolver(resolver);

                } else {
                    // Unsupported second factor.
                    setErrorMessage('Unsupported second factor');
                }
            } else if (error.code === 'auth/wrong-password') {
                // Handle other errors such as wrong password.
                setErrorMessage('Invalid email or password');
            } else {
                // Handle other authentication errors.
                setErrorMessage('Authentication error');
            }
        }
    };



    const handleSendSms = async (e) => {
        e.preventDefault();

        const phoneAuthProvider = new PhoneAuthProvider(auth);

        try {
            const verificationId = await phoneAuthProvider.verifyPhoneNumber(
                {
                    multiFactorHint: resolver.hints[0],
                    session: resolver.session
                },
                recaptchaVerifier
            );

            setVerificationId(verificationId)

        } catch (verificationError) {
            // Handle the SMS verification error.
            setErrorMessage('Error verifying phone number');
        }
    };

    const handleSubmitSms = async (e) => {
        e.preventDefault();

        try {

            // Ask user for the SMS verification code. Then:
            const cred = PhoneAuthProvider.credential(verificationId, smsCode);
            const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);

            // Complete sign-in.
            const userCredential = await resolver.resolveSignIn(multiFactorAssertion);
            // User successfully signed in with the second factor phone number.
            setErrorMessage('');
        } catch (verificationError) {
            console.log("FAILED: " + verificationError);
            // Handle the SMS verification error.
            setErrorMessage('Error verifying phone number');
        }
    };

    return (
        <Container component="main" maxWidth="xs">
            <div id="recaptcha-container" ref={recaptchaVerifierRef}></div>

            <CssBaseline />
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                    {/* Include your avatar icon */}
                </Avatar>
                <Typography component="h1" variant="h5">
                    Sign in
                </Typography>
                {!resolver ?
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            value={email}
                            onChange={handleEmailChange}
                            id="email"
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                            autoFocus
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            value={password}
                            onChange={handlePasswordChange}
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                        />

                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Sign In
                        </Button>
                        {errorMessage && (
                            <Typography color="error" variant="body2" sx={{ mt: 2 }}>
                                {errorMessage}
                            </Typography>
                        )}
                    </Box>
                    :
                    <Box component="form" noValidate sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            value={smsCode}
                            onChange={handleSmsChange}
                            name="smsCode"
                            label="Code"
                            type="text"
                            id="sms"
                            autoComplete="sms-code"
                        />
                        <Button size="small" onClick={handleSendSms}>{`Send code now`}</Button>
                        <Button
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                            onClick={handleSubmitSms}>{`Submit Code`}</Button>
                    </Box>
                }
            </Box>
        </Container>
    );
};

export default SignIn;
