import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, IconButton, Typography, Box, List, ListItem, Link } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const ChessDialog = ({ open, onClose }) => {
    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="lg" >
            <DialogTitle variant='h4'>
                About This App
                <IconButton
                    edge="end"
                    color="inherit"
                    onClick={onClose}
                    aria-label="close"
                    sx={{ position: 'absolute', right: 20, top: 8 }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Typography variant="body1" paragraph>
                    CHESS TRAINER is a practice tool designed to help you solve chess puzzles and improve your game. The app generates random chess positions and evaluates the current board state using the Stockfish engine.
                </Typography>
                <Typography variant="h6" gutterBottom>
                    How it works:
                </Typography>
                <List>
                    <ListItem><Typography>1. Use the <strong>Randomize</strong> button to generate a new puzzle position.</Typography></ListItem>
                    <ListItem><Typography>2. Try to find the best move.</Typography></ListItem>
                    <ListItem><Typography>3. Click <strong>Best Move</strong> to see the best move in the current position.</Typography></ListItem>
                    <ListItem><Typography>4. The <strong>Undo</strong> button lets you step back one move.</Typography></ListItem>
                </List>
                <Typography variant="h6" gutterBottom>
                    Evaluation Bar:
                </Typography>
                <Typography variant="body1" paragraph>
                    The evaluation bar on the right side of the board provides a quick visual representation of who is winning:
                </Typography>
                <List>
                    <ListItem>If the bar is more white, it means White is in a stronger position.</ListItem>
                    <ListItem>If the bar is more black, it means Black is in a stronger position.</ListItem>
                    <ListItem>A completely white or black bar indicates a decisive advantage or checkmate.</ListItem>
                </List>
                <Typography variant="h6" gutterBottom>
                    Acknowledgments
                </Typography>
                <Typography variant="body1" paragraph>
                    This app integrates the Stockfish chess engine for move analysis and evaluation. Stockfish is an open-source engine. The chess community is grateful to the Stockfish development team for their excellent work. You can find the source code and
                    license details on the official <Link href="https://github.com/official-stockfish/Stockfish" target="_blank" rel="noopener">Stockfish repository</Link>
                </Typography>
                <Typography
                    variant="body2"
                    align="center"
                    sx={{ fontFamily: 'Caveat', fontSize: '14px', mt: 2, color: '#555' }}
                >
                    {/* <Typography variant='subtitle2'>Created by Clinton</Typography> */}
                </Typography>
            </DialogContent>
        </Dialog>
    );
};

export default ChessDialog;
