import React, { createContext, useContext, useEffect, useState } from 'react';
import { MenuListObject, MenuItemObject, SubMenuItemObject } from '../classes/Menu'; // Update with the actual path
import { DB_PATHS, DB_FIELDS } from '../constants';
import { getAuth } from 'firebase/auth';
import { useAuth } from './AuthContext';
import { db, dbFunctions } from '../firebase';
import { createMenuItemId } from '../functions.js/generalFunctions';
import { useDialog } from './DialogContext';


const ModuleDataContext = createContext();

export const ModuleDataProvider = ({ children }) => {

    const { openDialog } = useDialog();

    const { currentUser } = useAuth();
    const [pageId, setPageId] = useState('home');
    const [moduleListData, setModuleListData] = useState([]);
    const [objectModuleListData, setObjectModuleListData] = useState(null);
    const userDocRef = dbFunctions.doc(db, DB_PATHS.USERS, currentUser.uid, 'pages', 'home');


    const fetchAllModuleData = async () => {
        // console.log(user)
        try {

            const userData = await dbFunctions.getDoc(userDocRef);

            console.log(userData.data()['data']);
            setModuleListData(userData.data()['data'])
            return;

            if (!userData.exists()) {
                // // Data is empty, create default menu item
                // const homeMenuItem = new MenuItem("home", "Home", "home");
                // console.log([homeMenuItem])


                // // Save default menu item to Firestore
                // await dbFunctions.setDoc(userDocRef, { [DB_FIELDS.MENU]: [homeMenuItem.toObject()] });

                // // Update state with default menu item
                // setObjectModuleListData([homeMenuItem]);
            } else {
                console.log("Menu_exists")
                const data = new MenuListObject(userData.data()[DB_FIELDS.MENU]);

                // Data exists, update state with fetched menu data
                setModuleListData(data)
                // setObjectModuleListData(data);
            }
        } catch (error) {
            console.error('Error fetching or updating data:', error);
        }
    };

    useEffect(() => {


        if (objectModuleListData) {
            setModuleListData(objectModuleListData.toObject())
        }
    }, [objectModuleListData]); // Run only once on component mount

    useEffect(() => {
        console.log("TEST: ")

        if (currentUser) {
            fetchAllModuleData();
        }

    }, [currentUser]); // Run only once on component mount





    const createModule = async (itemId, subItemId, icon, menuTitle) => {

        console.log(itemId, subItemId, icon, menuTitle)

        const newObject = new MenuListObject(moduleListData);


        const id = createMenuItemId(icon);
        if (itemId) {
            const subItem = new SubMenuItemObject(id, menuTitle, icon);
            newObject.addSubItem(itemId, subItem);
        }
        else {
            console.log("MADE1")
            const menuItem = new MenuItemObject(id, menuTitle, icon);
            newObject.addMenuItem(menuItem);
        }

        console.log(newObject)
        // Save default menu item to Firestore
        await dbFunctions.setDoc(userDocRef, { [DB_FIELDS.MENU]: newObject.toObject() });
        setObjectModuleListData(newObject);
        await fetchAllModuleData();

    }


    const updateModule = async (itemId, subItemId, icon, menuTitle) => {

        console.log(itemId, subItemId, icon, menuTitle)

        const newObject = new MenuListObject(moduleListData);

        if (itemId && subItemId) {
            const subItem = new SubMenuItemObject(subItemId, menuTitle, icon);
            newObject.updateSubItem(itemId, subItemId, subItem);
        }
        else if (itemId) {
            const menuItem = new MenuItemObject(itemId, menuTitle, icon);
            newObject.updateMenuItem(itemId, menuItem);
        }


        console.log(newObject)

        // Save default menu item to Firestore
        await dbFunctions.setDoc(userDocRef, { [DB_FIELDS.MENU]: newObject.toObject() });
        setObjectModuleListData(newObject);
        await fetchAllModuleData();

    }


    const deleteModule = async (itemId, subItemId) => {

        const newObject = new MenuListObject(moduleListData);
        if (itemId && subItemId) {
            newObject.removeSubItem(itemId, subItemId);

        }
        else {
            newObject.removeMenuItem(itemId);
        }

        // Save default menu item to Firestore
        await dbFunctions.setDoc(userDocRef, { [DB_FIELDS.MENU]: newObject.toObject() });
        setObjectModuleListData(newObject);
        await fetchAllModuleData();

    }

    return (
        <ModuleDataContext.Provider value={{
            setPageId,
            pageId,
            moduleListData,
            fetchAllModuleData,
            createModule,
            updateModule,
            deleteModule
        }}>
            {children}
        </ModuleDataContext.Provider>
    );
};


export const useModuleListData = () => {
    const context = useContext(ModuleDataContext);
    if (!context) {
        throw new Error('useMenuData must be used within a MenuDataProvider');
    }
    return context;
};

