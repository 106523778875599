import React, { useState } from 'react'
import { styled, useTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Menu, Switch, Tooltip, useMediaQuery } from '@mui/material';
import { useThemeContext } from '../Context/ThemeContext';
import CustomMenu from '../components/Menu/MainMenu';
import About from './About';
import Career from './Career/CityOfVancouverTradesSupervisor';
import WebDev from './Hobbies/WebDev';
import Photography from './Hobbies/Photography';
import { useAuth } from '../Context/AuthContext';
import { ModuleListObject } from '../classes/Modules';
import ModuleList from '../components/Modules/ModuleList';
import { useModuleListData } from '../Context/ModuleDataContext';


function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="clintonmurtagh.com">
                clintonmurtagh.com
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const drawerWidth = 300;

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        '& .MuiDrawer-paper': {
            position: 'relative',
            whiteSpace: 'nowrap',
            width: drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            boxSizing: 'border-box',
            ...(!open && {
                overflowX: 'hidden',
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
                width: theme.spacing(7),
                [theme.breakpoints.up('sm')]: {
                    width: theme.spacing(12),
                },
            }),
        },
    }),
);

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
    width: 62,
    height: 34,
    padding: 7,
    '& .MuiSwitch-switchBase': {
        margin: 1,
        padding: 0,
        transform: 'translateX(6px)',
        '&.Mui-checked': {
            color: '#fff',
            transform: 'translateX(22px)',
            '& .MuiSwitch-thumb:before': {
                backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
                    '#fff',
                )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
            },
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',


            },
        },
    },
    '& .MuiSwitch-thumb': {
        backgroundColor: theme.palette.mode === "dark" ? theme.palette.secondary.dark : theme.palette.secondary.main,
        width: 32,
        height: 32,
        '&:before': {
            content: "''",
            position: 'absolute',
            width: '100%',
            height: '100%',
            left: 0,
            top: 0,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
                '#fff',
            )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
        },
    },
    '& .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
        borderRadius: 20 / 2,
    },
}));


export default function Dashboard() {

    const { logout } = useAuth();
    const theme = useTheme();
    const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [open, setOpen] = useState(true);
    const [openSmallMenu, setOpenSmallMenu] = useState(false);
    const [currentPage, setCurrentPage] = useState('about');
    const [anchorEl, setAnchorEl] = React.useState(null);
    const { setIsDarkMode, isDarkMode } = useThemeContext();

    const { setPageId } = useModuleListData();

    const handleSmallMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };


    const toggleDrawer = () => {

        if (isMediumScreen) {
            setOpenSmallMenu(!openSmallMenu);
        }
        else {
            setOpen(!open);
        }
    };


    const handleToggleDarkMode = () => {
        setIsDarkMode(!isDarkMode);
    };


    const handleItemClick = (item) => {
        if (item.id === 'logout') {
            logout();
        }
        else {
            setCurrentPage(item.id);
            setPageId(item.id);

        }
        setOpenSmallMenu(false);
        setAnchorEl(null);

    };
    return (


        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar position="absolute" open={open && !isMediumScreen}>
                <Toolbar
                    sx={{
                        pr: '24px', // keep right padding when drawer closed
                        backgroundColor: theme.palette.mode === "dark" ? theme.palette.primary.dark : theme.palette.primary.main
                    }}
                >
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={toggleDrawer}
                        sx={{
                            marginRight: '36px',
                            ...(open && !isMediumScreen && { display: 'none' }),
                        }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography
                        component="h1"
                        variant="h6"
                        color="inherit"
                        noWrap
                        sx={{ flexGrow: 1 }}
                    >
                        {"Clinton M"}
                    </Typography>


                    <Tooltip title="Toggle Light/Dark Mode">
                        <MaterialUISwitch onChange={handleToggleDarkMode} checked={isDarkMode} />
                    </Tooltip>
                </Toolbar>
            </AppBar>

            {!isMediumScreen ?
                <>
                    <Drawer variant="permanent" open={open}>
                        <Toolbar
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'flex-end',
                                px: [1],
                            }}
                        >
                            <IconButton onClick={toggleDrawer}>
                                <ChevronLeftIcon />
                            </IconButton>
                        </Toolbar>
                        <Divider />
                        <List component="nav">
                            <CustomMenu currentPage={currentPage} handleItemClick={handleItemClick} drawerOpen={open} />
                        </List>
                    </Drawer>

                </>
                :

                <Menu
                    id="demo-positioned-menu"
                    aria-labelledby="demo-positioned-button"
                    anchorEl={anchorEl}
                    open={openSmallMenu}
                    onClose={() => setOpenSmallMenu(!openSmallMenu)}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                >
                    <CustomMenu currentPage={currentPage} handleItemClick={handleItemClick} drawerOpen={true} />
                </Menu>

            }
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    height: '100vh',
                    overflow: 'auto',
                }}
            >
                <Toolbar />
                <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>


                    <ModuleList />

                    {/* {currentPage === 'about' &&
                        <About />
                    }
                    {currentPage === 'cityofvancouver' &&
                        <Career />
                    }
                    {currentPage === 'webdev' &&
                        <WebDev />
                    }
                    {currentPage === 'photography' &&
                        <Photography />
                    } */}

                    <Copyright sx={{ pt: 4 }} />
                </Container>
            </Box>
        </Box>

    );
}