import React, { useEffect, useRef, useState } from 'react';
import { ListItemButton, ListItemIcon, ListItemText, List, Collapse, Divider, ListItem, IconButton, ListItemAvatar, Avatar, Button, Box } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { useMenuData } from '../../Context/MenuDataContext';
import { useAuth } from '../../Context/AuthContext';
import 'material-icons/iconfont/material-icons.css';
import { Icon } from '@mui/material';
import { FormControl } from '@mui/material';
import iconsList from '../../constants/iconsList';
import { Virtuoso } from 'react-virtuoso'
import { Menu } from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import CustomActionButton from '../CustomActionButton';
import ValidationTextFields from '../ValidationTextField';
import CustomEditingBox from '../CustomEditingBox';
import MenuItemSetup from './MenuItemSetup';
import MenuSubItem from './MenuSubItem';



// Define your styled ListItem
const StyledListItem = styled(ListItem)({
  '&:hover': {
    cursor: 'pointer',
  },
});

const MenuSubItems = ({ item, open, handleItemClick, drawerOpen, updateMenuItem, deleteMenuItem }) => {

  const hasMenuItems = (item.subItems && item.subItems.length > 0) ? true : false;
  return (
    <>
      {hasMenuItems &&
        <Box sx={{ width: '100%' }}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Divider />
            <List component="div" disablePadding>
              {item.subItems.map((subItem, index) => (
                <MenuSubItem index={index} item={item} subItem={subItem} open={open} handleItemClick={handleItemClick} drawerOpen={drawerOpen} updateMenuItem={updateMenuItem} deleteMenuItem={deleteMenuItem} />
              ))}
            </List>
          </Collapse>
        </Box>
      }
    </>
  );
};

export default MenuSubItems;
