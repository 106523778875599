import * as React from 'react';
import { Avatar, Icon, IconButton, Stack, useTheme } from '@mui/material';
import 'material-icons/iconfont/material-icons.css';

export default function CustomActionButton({ size, color, icon, onClick }) {
    const theme = useTheme();

    const buttonSize = size === 'medium' ? 26 : size === "small" ? 22 : 30; // Adjust the size as needed
    const iconSize = size === 'medium' ? 20 : size === "small" ? 16 : 24; // Adjust the size as needed


    return (

        <Avatar sx={{ m: 0.5, backgroundColor: theme.palette.action.selected, width: buttonSize, height: buttonSize }}>
            <IconButton sx={{ backgroundColor: color ? color : theme.palette.warning.dark }} onClick={onClick}>
                <Icon sx={{ fontSize: iconSize }}>{icon}</Icon>
            </IconButton>
        </Avatar>

    );
}
