export class SubMenuItemObject {
    constructor(id, text = "", icon = "", subText = "") {
        this.id = id;
        this.icon = icon;
        this.text = text;
        this.subText = subText;
    }

    toObject() {
        return {
            id: this.id,
            icon: this.icon,
            text: this.text,
            subText: this.subText,
        };
    }
}

export class MenuItemObject {
    constructor(id, text = "", icon = "", subItems = []) {
        this.id = id;
        this.icon = icon;
        this.text = text;
        this.subItems = subItems.map(subItemData => new SubMenuItemObject(subItemData.id, subItemData.text, subItemData.icon, subItemData.subText));
    }

    addSubItem(subItem) {
        this.subItems.push(subItem);
    }

    removeSubItem(subItemId) {
        this.subItems = this.subItems.filter(subItem => subItem.id !== subItemId);
    }

    updateSubItem(subItemId, updatedSubItem) {
        this.subItems = this.subItems.map(subItem => {
            if (subItem.id === subItemId) {
                return Object.assign(subItem, updatedSubItem);
            }
            return subItem;
        });
    }

    toObject() {
        return {
            id: this.id,
            icon: this.icon,
            text: this.text,
            subItems: this.subItems.map(subItem => subItem.toObject()), // Recursively convert subItems
        };
    }
}

export class MenuListObject {
    constructor(menuListData = []) {
        this.menuItems = menuListData.map(menuItemData => new MenuItemObject(menuItemData.id, menuItemData.text, menuItemData.icon, menuItemData.subItems));
    }

    addMenuItem(menuItem) {
        this.menuItems.push(menuItem);
    }

    removeMenuItem(menuItemId) {
        this.menuItems = this.menuItems.filter(item => item.id !== menuItemId);
    }

    updateMenuItem(menuItemId, updatedMenuItem) {
        this.menuItems = this.menuItems.map(item => {
            if (item.id === menuItemId) {
                // Update individual properties of the menu item
                item.text = updatedMenuItem.text !== undefined ? updatedMenuItem.text : item.text;
                item.icon = updatedMenuItem.icon !== undefined ? updatedMenuItem.icon : item.icon;
                // Add more properties if needed
    
                // Do not update subItems
            }
            return item;
        });
    }
    

    getMenuItem(menuItemId) {
        return this.menuItems.find(item => item.id === menuItemId);
    }

    addSubItem(menuItemId, subItem) {
        const menuItem = this.getMenuItem(menuItemId);
        if (menuItem) {
            menuItem.addSubItem(subItem);
        }
    }

    removeSubItem(menuItemId, subItemId) {
        const menuItem = this.getMenuItem(menuItemId);
        if (menuItem) {
            menuItem.removeSubItem(subItemId);
        }
    }

    updateSubItem(menuItemId, subItemId, updatedSubItem) {
        const menuItem = this.getMenuItem(menuItemId);
        if (menuItem) {
            menuItem.updateSubItem(subItemId, updatedSubItem);
        }
    }

    getMenuItems() {
        return this.menuItems.map(item => item.toObject());
    }

    toObject() {
        return this.getMenuItems();
    }
}
