import * as React from 'react';
import { Box, Stack, useTheme } from '@mui/material';
import 'material-icons/iconfont/material-icons.css';
import CustomActionButton from './CustomActionButton';

export default function CustomEditingBox({ children, ...props }) {
    const { cancel, updateMenuItem, deleteMenuItem } = props;
    const theme = useTheme();
    return (
        <Box {...props} sx={props?.editing && { position: 'relative', border: `2px dashed ${theme.palette.info.main}` }}>
            <Box sx={{ display: 'flex', flexGrow: 1 }}>
                <Box sx={{ display: 'flex', flexGrow: 1 }}>
                    <CustomActionButton icon={"delete"} size="medium" color={theme.palette.action.hover} onClick={deleteMenuItem} />
                </Box>
                <Box sx={{ display: 'flex', }}>
                    <Stack direction={'row'}>
                        <CustomActionButton icon={"save"} size="medium" color={theme.palette.warning.dark} onClick={updateMenuItem} />
                        <CustomActionButton icon={"close"} size="medium" color={theme.palette.info.dark} onClick={cancel} />
                    </Stack>
                </Box>
            </Box>
            <Box sx={{ p: 2 }}>
                {children}
            </Box>
        </Box>
    );
}
