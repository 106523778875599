import Dashboard from './Pages/Dashboard';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ErrorPage from './Pages/ErrorPage';
import SignIn from './Pages/SignIn';
import PrivateRoute from './PrivateRoute';
import { AuthProvider } from './Context/AuthContext';
import { MenuDataProvider } from './Context/MenuDataContext';
import { ModuleDataProvider } from './Context/ModuleDataContext';
import Chess from './Pages/Chess/Chess';

function App() {
  return (
    <>
      <Router>
        <AuthProvider>
          <Routes>
            <Route path="/404" element={<ErrorPage />} />{" "}
            <Route path="/home" element={
              <PrivateRoute>
                <ModuleDataProvider>
                  <MenuDataProvider>
                    <Dashboard />
                  </MenuDataProvider>
                </ModuleDataProvider>
              </PrivateRoute>
            }
            />
            <Route path="/signin" element={
              <SignIn />} />{" "}
            <Route path="*" element={
              <SignIn />} />{" "}
            <Route path="/chess" element={
              <Chess />} />{" "}
          </Routes>{" "}
        </AuthProvider>
      </Router>{" "}
    </>
  );
}

export default App;
