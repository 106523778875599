import React, { useContext, useState, useEffect, createContext } from "react"
import { useNavigate } from 'react-router-dom';
import { auth, db } from "../firebase";
import { authFunctions } from "../firebase";
import { setDoc } from "firebase/firestore";
import { dbFunctions } from '../firebase';
import { DB_FIELDS, DB_PATHS, DB_DEFAULT_VALUES } from "../constants";
import { Box } from "@mui/material";


const AuthContext = createContext()

export function useAuth() {
    return useContext(AuthContext)
}

export function AuthProvider({ children }) {
    const navigate = useNavigate();
    const [currentUser, setCurrentUser] = useState(null)
    const [loading, setLoading] = useState(true)
    const [userAdmin, setUserAdmin] = useState(false)
    const [userAuthGuest, setUserAuthGuest] = useState(false)
    const [phoneInfoOptions, setPhoneInfoOptions] = useState(null)
    const [resolver, setResolver] = useState(null)



    // Sign in with Email and Password
    async function login(email, password) {
        return await authFunctions.setPersistence(auth, authFunctions.browserSessionPersistence)
            .then(() => {

                return authFunctions.signInWithEmailAndPassword(auth, email, password);
            })
            .then(async (userCredential) => {

                // Signed in 
                const user = userCredential.user;
                setCurrentUser(user)
                return null;
            })
            .catch((error) => {
                if (error.code == 'auauthFunctions.th/multi-factor-auth-required') {
                    const resolver = authFunctions.getMultiFactorResolver(auth, error);
                    setResolver(resolver);
                    console.log(resolver)
                    // Ask user which second factor to use.
                    // if (resolver.hints[selectedIndex].factorId ===
                    //     authFunctions.PhoneMultiFactorGenerator.FACTOR_ID) {
                        // const phoneInfoOptions = {
                        //     multiFactorHint: resolver.hints[selectedIndex],
                        //     session: resolver.session
                        // };
                        // setPhoneInfoOptions(phoneInfoOptions);

                    // } 
                    // else if (resolver.hints[selectedIndex].factorId ===
                    //     TotpMultiFactorGenerator.FACTOR_ID) {
                    //     // Handle TOTP MFA.
                    //     // ...
                    // } else {
                    //     // Unsupported second factor.
                    // }
                } else if (error.code == 'auth/wrong-password') {
                    // Handle other errors such as wrong password.
                    console.log(error)

                    return handleSignInError(error);
                }
                console.log(error)

                return handleSignInError(error);

            });
    }



    // Submit SMS code along with recaptchaVerifier (This will run only if the user is setup for double authentication)
    async function submitVerificationCode(verificationCode, recaptchaVerifier) {

        const phoneAuthProvider = new authFunctions.PhoneAuthProvider(auth);
        // Send SMS verification code
        return phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier)
            .then(function (verificationId) {
                // Ask user for the SMS verification code. Then:
                const cred = authFunctions.PhoneAuthProvider.credential(
                    verificationId, verificationCode);
                const multiFactorAssertion =
                    authFunctions.PhoneMultiFactorGenerator.assertion(cred);
                // Complete sign-in.
                return resolver.resolveSignIn(multiFactorAssertion)
            })
            .then(function (userCredential) {
                // Signed in 
                const user = userCredential.user;
                setCurrentUser(user)
                return null;
                // User successfully signed in with the second factor phone number.
            });

    }



    // Check if user is Admin
    async function isAdmin(currentUser) {
        if (currentUser && currentUser !== null) {
            return currentUser.getIdTokenResult()
                .then((idTokenResult) => {
                    // Confirm the user is an Admin.
                    if (idTokenResult.claims.admin) {
                        // Show admin UI.

                        return true;
                    } else {
                        // Show regular user UI.

                        return false;
                    }
                })
                .catch((error) => {
                    return false;
                });
        }
        return false;
    }


    // Check if user is Admin Viewer
    async function isAuthGuest(currentUser) {
        if (currentUser && currentUser !== null) {
            return currentUser.getIdTokenResult()
                .then((idTokenResult) => {
                    // Confirm the user is an Admin.
                    if (idTokenResult.claims.authGuest) {
                        // Show admin UI.

                        return true;
                    } else {
                        // Show regular user UI.

                        return false;
                    }
                })
                .catch((error) => {
                    return false;
                });
        }
        return false;
    }



    function handleSignInError(error) {
        const errorCode = error.code;
        if (errorCode == 'auth/wrong-password') {
            return ('Failed login');
        } else if (errorCode == 'auth/user-disabled') {
            return ('Account temporarily disabled. Reset password or try again later.');
        } else {
            return ('Failed login');
        }
    }


    function logout() {
        return authFunctions.signOut(auth)
    }


    useEffect(() => {
        const unsubscribe = authFunctions.onAuthStateChanged(auth, async (user) => {

            var admin = await isAdmin(user);
            setUserAdmin(admin);
            var authGuest = await isAuthGuest(user);
            setUserAuthGuest(authGuest);
            console.log(user)

            setCurrentUser(user)

            if (user) {
                navigate("/home");
            }
            else {
                logout();
            }

            setLoading(false)
        })

        return unsubscribe
    }, [])


    return (
        <AuthContext.Provider value={{
            auth,
            currentUser,
            submitVerificationCode,
            login,
            logout,
            userAdmin,
            userAuthGuest
        }}>
            {!loading && children}
        </AuthContext.Provider>
    )
}
