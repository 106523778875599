import React, { createContext, useContext, useEffect, useState } from 'react';
import { MenuListObject, MenuItemObject, SubMenuItemObject } from '../classes/Menu'; // Update with the actual path
import { DB_PATHS, DB_FIELDS } from '../constants';
import { getAuth } from 'firebase/auth';
import { useAuth } from './AuthContext';
import { db, dbFunctions } from '../firebase';
import { createMenuItemId } from '../functions.js/generalFunctions';
import { useDialog } from './DialogContext';


const MenuDataContext = createContext();

export const MenuDataProvider = ({ children }) => {

    const { openDialog } = useDialog();

    const { currentUser } = useAuth();
    const [menuData, setMenuData] = useState([]);
    const [objectMenuData, setObjectMenuData] = useState(null);
    const userDocRef = dbFunctions.doc(db, DB_PATHS.USERS, currentUser.uid);


    const fetchAllMenuData = async () => {
        // console.log(user)
        try {

            const userData = await dbFunctions.getDoc(userDocRef);

            if (!userData.exists()) {
                // // Data is empty, create default menu item
                // const homeMenuItem = new MenuItem("home", "Home", "home");
                // console.log([homeMenuItem])


                // // Save default menu item to Firestore
                // await dbFunctions.setDoc(userDocRef, { [DB_FIELDS.MENU]: [homeMenuItem.toObject()] });

                // // Update state with default menu item
                // setObjectMenuData([homeMenuItem]);
            } else {
                console.log("Menu_exists")
                const data = new MenuListObject(userData.data()[DB_FIELDS.MENU]);

                // Data exists, update state with fetched menu data
                setObjectMenuData(data);
            }
        } catch (error) {
            console.error('Error fetching or updating data:', error);
        }
    };

    useEffect(() => {


        if (objectMenuData) {
            console.log(objectMenuData.toObject())
            setMenuData(objectMenuData.toObject())
        }
    }, [objectMenuData]); // Run only once on component mount

    useEffect(() => {
        console.log("TEST: ")

        if (currentUser) {
            fetchAllMenuData();
        }

    }, [currentUser]); // Run only once on component mount



    // const addMenuItem = async (itemId, icon, menuTitle) => {

    //     // console.log(itemId, icon, menuTitle);
    //     // return;
    //     const newObject = new MenuListObject(menuData);
    //     const id = createMenuItemId(icon);
    //     if (itemId) {
    //         const subItem = new SubMenuItemObject(id, menuTitle, icon);
    //         newObject.addSubItem(itemId, subItem);
    //     }
    //     else {
    //         const menuItem = new MenuItemObject(id, menuTitle, icon);
    //         newObject.addMenuItem(menuItem);
    //     }
    //     console.log("REULST")
    //     console.log(newObject.toObject());

    //     // Save default menu item to Firestore
    //     await dbFunctions.setDoc(userDocRef, { [DB_FIELDS.MENU]: newObject.toObject() });
    //     setObjectMenuData(newObject);
    //     await fetchAllMenuData();
    // }

    // const addMenuSubItem = async (itemId, subItemId, icon, menuTitle) => {
    //     // // const id = createMenuItemId(menuTitle);
    //     // const id = createMenuItemId(icon);
    //     // // Data is empty, create default menu item
    //     // const homeMenuItem = new SubMenuItem(id, menuTitle, icon);

    //     // // Save default menu item to Firestore
    //     // await dbFunctions.setDoc(userDocRef, { [DB_FIELDS.MENU]: [...menuData, homeMenuItem.toObject()] });
    //     // await fetchAllMenuData();
    // }



    const createMenuItem = async (itemId, subItemId, icon, menuTitle) => {

        console.log(itemId, subItemId, icon, menuTitle)

        const newObject = new MenuListObject(menuData);


        const id = createMenuItemId();
        if (itemId) {
            const subItem = new SubMenuItemObject(id, menuTitle, icon);
            newObject.addSubItem(itemId, subItem);
        }
        else {
            console.log("MADE1")
            const menuItem = new MenuItemObject(id, menuTitle, icon);
            newObject.addMenuItem(menuItem);
        }

        console.log(newObject)
        // Save default menu item to Firestore
        await dbFunctions.setDoc(userDocRef, { [DB_FIELDS.MENU]: newObject.toObject() });
        setObjectMenuData(newObject);
        await fetchAllMenuData();

    }


    const updateMenuItem = async (itemId, subItemId, icon, menuTitle) => {

        console.log(itemId, subItemId, icon, menuTitle)

        const newObject = new MenuListObject(menuData);

        if (itemId && subItemId) {
            const subItem = new SubMenuItemObject(subItemId, menuTitle, icon);
            newObject.updateSubItem(itemId, subItemId, subItem);
        }
        else if (itemId) {
            const menuItem = new MenuItemObject(itemId, menuTitle, icon);
            newObject.updateMenuItem(itemId, menuItem);
        }


        console.log(newObject)

        // Save default menu item to Firestore
        await dbFunctions.setDoc(userDocRef, { [DB_FIELDS.MENU]: newObject.toObject() });
        setObjectMenuData(newObject);
        await fetchAllMenuData();

    }


    const deleteMenuItem = async (itemId, subItemId) => {

        const newObject = new MenuListObject(menuData);
        if (itemId && subItemId) {
            newObject.removeSubItem(itemId, subItemId);

        }
        else {
            newObject.removeMenuItem(itemId);
        }

        // Save default menu item to Firestore
        await dbFunctions.setDoc(userDocRef, { [DB_FIELDS.MENU]: newObject.toObject() });
        setObjectMenuData(newObject);
        await fetchAllMenuData();

    }


    // Function to find the object with a specific ID and update its data
    function updateMainItemDataById(arrayObjects, idToUpdate, newData) {
        var indexOfObjectToUpdate = arrayObjects.findIndex(obj => obj.id === idToUpdate);

        if (indexOfObjectToUpdate >= 0) {
            arrayObjects[indexOfObjectToUpdate] = newData.toObject();
            return arrayObjects;
            // Update the data property of the found object

        } else {
            return null;
        }
    }


    // Function to find the object with a specific ID and update its data
    function updateSubItemDataById(arrayObjects, idToUpdate, subItemIdToUpdate, newData) {
        var indexOfObjectToUpdate = arrayObjects.findIndex(obj => obj.id === idToUpdate);

        if (indexOfObjectToUpdate >= 0) {
            var subObject = arrayObjects[indexOfObjectToUpdate];

            var indexOfSubObjectToUpdate = subObject.findIndex(obj => obj.id === subItemIdToUpdate);

            if (indexOfSubObjectToUpdate >= 0) {
                subObject[indexOfSubObjectToUpdate] = newData.toObject();
                return arrayObjects;
            }
            else {
                return null;
            }
            // Update the data property of the found object
        } else {
            return null;
        }
    }

    return (
        <MenuDataContext.Provider value={{
            menuData,
            fetchAllMenuData,
            createMenuItem,
            updateMenuItem,
            deleteMenuItem
        }}>
            {children}
        </MenuDataContext.Provider>
    );
};


export const useMenuData = () => {
    const context = useContext(MenuDataContext);
    if (!context) {
        throw new Error('useMenuData must be used within a MenuDataProvider');
    }
    return context;
};

