import React from "react"
import { Route, Navigate } from "react-router-dom"
import { useAuth } from "./Context/AuthContext"

export default function PrivateRoute({ children }) {
  const { currentUser, userAdmin, userAuthGuest } = useAuth()

  if (!currentUser || !(userAdmin || userAuthGuest)) {
    return (
      <Navigate to="/signin" />
    )
  }
  else {
    return (userAdmin || userAuthGuest) ? children : <Navigate to="/signin" />
  }
}
