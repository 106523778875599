import { Backdrop, Box, Button, CircularProgress, Paper, Stack, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react'
import AlertDialogSlide from './AlertDialog';
import ChessDialog from './ChessDialog';
import UndoIcon from '@mui/icons-material/Undo';
import StarIcon from '@mui/icons-material/Star';
import ShuffleIcon from '@mui/icons-material/Shuffle';
import InfoIcon from '@mui/icons-material/Info';
import { useThemeContext } from '../../Context/ThemeContext';
import LightModeIcon from '@mui/icons-material/LightMode';
import DarkModeIcon from '@mui/icons-material/DarkMode';


export default function Chess() {

    const isLarge = useMediaQuery('(min-width:600px)');
    const theme = useTheme();
    const { setIsDarkMode, isDarkMode } = useThemeContext();
    const handleToggleDarkMode = () => {
        setIsDarkMode(!isDarkMode);
    };

    const isVerySmall = useMediaQuery('(max-width:400px)');


    const [htmlContent, setHtmlContent] = useState('');
    const [loading, setLoading] = useState(false);
    const [showAbout, setShowAbout] = useState(false);
    const [alertDialiog, setAlertDialog] = useState({
        open: false,
        message: ''
    });
    function callFunc(func, variable) {
        switch (func) {
            case 'loading':
                setLoading(variable)
                break;
            case 'alert':
                setAlertDialog({
                    open: true,
                    message: variable
                })
                break;
            case 'about':
                setShowAbout(true)
                break;
        }

    }


    const closeAlert = () => {
        setAlertDialog({
            open: false,
            message: ''
        })
    }

    useEffect(() => {
        // Expose the function globally
        window.callReactFunction = callFunc;
        fetch('../../chess/myChessPage.html')
            .then(response => response.text())
            .then(async (data) => {
                setHtmlContent(data);

                // Create and add a script element

                const scriptSources = [
                    'https://code.jquery.com/jquery-3.6.0.min.js',
                    'https://cdnjs.cloudflare.com/ajax/libs/chess.js/0.10.2/chess.min.js',
                    '../../chess/static/js/chessboard-1.0.0.min.js',
                    '../../chess/static/js/custom.js'
                ];

                const loadScriptsSequentially = async () => {
                    for (const src of scriptSources) {
                        try {
                            await loadScript(src);
                        } catch (error) {
                            console.error(`Failed to load script ${src}: `, error);
                        }
                    }


                };

                const loadScript = async (src) => {
                    return new Promise((resolve, reject) => {
                        const script = document.createElement('script');
                        script.src = src;
                        script.async = true;
                        script.onload = resolve;
                        script.onerror = reject;
                        document.body.appendChild(script);
                    });
                };

                await loadScriptsSequentially();

                // Call the global function after scripts are loaded
                if (window.externalFunction) {
                    window.externalFunction(); // Call your exposed function
                }
                else {
                    console.error('Function externalFunction is not available.');
                }
            });

        // Clean up global function on component unmount
        return () => {
            delete window.callReactFunction;
        };
    }, []);

    return (
        <Box sx={{}}>
            <Backdrop
                sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
                open={loading}
                onClick={() => { setLoading(false) }}
            >
                <CircularProgress color="inherit" />

            </Backdrop>
            <Paper sx={{
                py: isLarge ? 5 : 3, m: isLarge ? 1 : 0, px: isLarge ? 10 : 0, justifyContent: 'center', alignItems: 'center',
                borderStyle: 'dashed'

            }} elevation={5}>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', mx: 2 }}>
                    <Tooltip title="Toggle Light/Dark Mode">
                        {isDarkMode ? <LightModeIcon onClick={handleToggleDarkMode} fontSize='large' /> : <DarkModeIcon onClick={handleToggleDarkMode} fontSize='large' />}
                    </Tooltip>
                </Box>
                <Box sx={{ flex: 1, justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column', px: 1 }}>

                    <Box sx={{ px: 2, pt: 1, mb: 1, }}>
                        <Typography variant='h3' sx={{ pb: 2, textAlign: 'center' }}>
                            Chess Trainer
                        </Typography>
                    </Box>
                    <Box sx={{}}>
                        <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
                    </Box>


                    <Paper
                        variant='flat'
                        elevation={4}
                        sx={{
                            backgroundColor: theme.palette.grey[250],
                            borderStyle: 'dashed',
                            mt: 2,
                            // my: 4,
                            mx: 1,
                            py: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: isLarge ? 500 : '100%',  // Adjust if needed
                            height: '100%'  // Adjust if needed
                        }}>

                        <Typography variant='h5'>Options</Typography>
                        <Box sx={{

                            pt: 1,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}>

                            <Stack
                                spacing={{ xs: 2, sm: 3 }}
                                direction={isLarge ? 'row' : 'column'}
                                useFlexGap
                                sx={{
                                    flexWrap: 'wrap',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    width: isLarge ? 'auto' : isVerySmall ? '100%' : 300,
                                    alignSelf: 'center',
                                    p: isLarge ? 5 : 0
                                }}
                            >
                                <Button startIcon={<UndoIcon />} variant="contained" onClick={() => { window.externalFunction('undoMove') }} sx={{ width: isLarge ? 'auto' : 300 }}>Undo</Button>
                                <Button startIcon={<StarIcon />} variant="contained" onClick={() => { window.externalFunction('bestMove') }} sx={{ width: isLarge ? 'auto' : 300 }}>Best Move</Button>
                                <Button startIcon={<ShuffleIcon />} variant="contained" onClick={() => { window.externalFunction('randomizeBoard') }} sx={{ width: isLarge ? 'auto' : 300 }}>Randomize</Button>
                                <Button startIcon={<InfoIcon />} variant="contained" onClick={() => setShowAbout(true)} sx={{ width: isLarge ? 'auto' : 300 }}>About</Button>
                            </Stack>
                        </Box>
                    </Paper>
                </Box>
            </Paper>

            <ChessDialog open={showAbout} onClose={() => setShowAbout(false)} />
            <AlertDialogSlide {...alertDialiog} handleClose={closeAlert} />
        </Box >
    );
}
