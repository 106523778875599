// dbConstants.js

export const DB_PATHS = {
    USERS: 'users',
    PAGES: 'pages',
    ORDERS: 'orders',
    // Add more tables as needed
};

export const DB_FIELDS = {
    MENU: 'menu',

    ID: 'id',
    TEXT: 'text',
};

export const DB_DEFAULT_VALUES = {
    ABOUT: 'about',
};

// Add more constants as needed
