import React, { createContext, useContext, useState } from 'react';
import { Dialog, DialogContent, DialogTitle, Box, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@emotion/react';
import { hexToRgb } from '../functions.js/generalFunctions';

const DialogContext = createContext();

export const DialogProvider = ({ children }) => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState(null);
  const [dialogTitle, setDialogTitle] = useState(null);
  const [noPadding, setNoPadding] = useState(false);
  const [maxWidth, setMaxWidth] = useState('xl');
  const [confirmationData, setConfirmationData] = useState(null);
  const [confirmationCallback, setConfirmationCallback] = useState(null);


  const openDialog = (title, content, onConfirm, confirmationData) => {
    setOpen(true);
    setDialogContent(content);
    setDialogTitle(title);
    setConfirmationCallback(() => onConfirm);
    setConfirmationData(confirmationData)
  };

  const closeDialog = () => {
    setOpen(false);
    setDialogContent(null);
    setConfirmationCallback(null);
  };


  const confirmAction = () => {
    if (confirmationCallback) {
      confirmationCallback(confirmationCallback);
    }
    closeDialog();
  };

  return (
    <DialogContext.Provider value={{ openDialog, closeDialog, setNoPadding, setMaxWidth }}>
      {children}
      <Dialog open={open} onClose={closeDialog} maxWidth={maxWidth} sx={{ overflow: 'hidden' }} maxWidth='sm'>
        <Box sx={{ position: 'absolute', top: 0, right: 0, p: 1 }}>
          <IconButton onClick={closeDialog} size="small" sx={{
            backgroundColor: (theme) => hexToRgb(theme.palette.primary.dark, 0.2), // Adjust alpha as needed
          }}>
            <CloseIcon htmlColor={'white'} />
          </IconButton>
        </Box>
        {dialogTitle &&
          <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            {dialogTitle}

          </DialogTitle>
        }
        <DialogContent sx={noPadding && { p: 0 }}>{dialogContent}</DialogContent>
        {confirmationCallback && (
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }}>
            <Button onClick={confirmAction} variant="contained" color="primary">
              Confirm
            </Button>
          </Box>
        )}
      </Dialog>
    </DialogContext.Provider>
  );
};

export const useDialog = () => {
  const context = useContext(DialogContext);
  if (!context) {
    throw new Error('useDialog must be used within a DialogProvider');
  }
  return context;
};
