import * as React from 'react';
import TextField from '@mui/material/TextField';

export default function ValidationTextFields({ id, label, fullWidth, inputRef, initialValue, onChange, limit }) {
    const [inputValue, setInputValue] = React.useState(initialValue || ''); // Set initial value

    const handleChange = (event) => {
        const newValue = event.target.value;
        console.log("VAL"+newValue)

        // Check if the length exceeds the limit
        if (limit && newValue.length > limit) {
            // Notify the user and prevent updating the input value
            return;
        }

        // Update the input value and trigger the callback
        setInputValue(newValue);
        if (onChange) {
            onChange(newValue);
        }
    };

    return (
        <TextField
            id={id}
            variant='outlined'
            fullWidth={fullWidth}
            label={label}
            inputRef={inputRef}
            error={limit && inputValue.length === limit}
            value={inputValue}
            onChange={handleChange}
            helperText={limit && inputValue.length === limit ? 'You have reached the limit' : ''}
        />
    );
}
